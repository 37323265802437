import { Seo, Text } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';
import { config } from 'config';
import { CircularProgress } from '@material-ui/core';
import Header from 'components/reviews/Header';
import Footer from 'components/reviews/Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          funnelCode: 'EF',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Seo
        title="Contact Us"
        description="Leave a message and get in touch with us"
        name="Contact Us"
      />
      <MainContainer>
        <Header isMobileApp={false} />
      </MainContainer>
      <ContactSection>
        <LeftSide>
          <LeftSideTitle>Our contact details</LeftSideTitle>
          <ItemWrap>
            <SmsLogo />
            <TextWrap>
              <Title>Email address</Title>
              <LeftSubTitle>hello@effecto.app</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Company</Title>
              <LeftSubTitle>Medical Score UAB</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Company code</Title>
              <LeftSubTitle>305445861</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>Address of registration</Title>
              <LeftSubTitle>
                J. Zemgulio str. 46, Panevezys, Lithuania
              </LeftSubTitle>
            </TextWrap>
          </ItemWrap>
          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>Address</Title>
              <LeftSubTitle>
                Antakalnio str. 17, Vilnius, Lithuania
              </LeftSubTitle>
            </TextWrap>
          </ItemWrap>
        </LeftSide>
        <RightSide>
          <RightSideTitle>Contact customer support</RightSideTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              Your email <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              placeholder="Please enter your email"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />

            <StyledLabel>
              Subject <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              placeholder="Please enter a subject"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />

            <StyledLabel>
              Description <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              placeholder="Your message"
              value={formData.message || ''}
            />

            <Note>
              Please enter the details of your request. A member of our team
              will respond as soon as possible.
            </Note>
            {error && (
              <ErrorText>There was an issue sending your email.</ErrorText>
            )}
            {success && (
              <SuccessText>Message was sent successfully!</SuccessText>
            )}
            <StyledButton disabled={loading ? true : false} type="submit">
              {loading ? (
                <CircularProgress style={{ color: '#fff' }} size={22} />
              ) : (
                'Send'
              )}
            </StyledButton>
          </form>
        </RightSide>
      </ContactSection>
      <Footer isMobileApp={false} />
    </>
  );
};

export default Contact;

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
  margin-top: 69px;

  a {
    font-family: Helvetica, sans-serif;
  }
`;

const ContactSection = styled.div`
  display: flex;
  margin: 0 1rem;
  column-gap: 96px;
  margin: auto;
  max-width: 100%;
  padding: 72px 2rem;
  max-width: 1124px;
  width: 100%;
  min-height: 78vh;
  justify-content: space-between;
  font-family: Helvetica, sans-serif;

  h1,
  p,
  div,
  label,
  input,
  textarea {
    color: #000;
  }

  label,
  input,
  input::placeholder,
  div,
  textarea {
    font-family: Helvetica, sans-serif;
  }

  @media (max-width: 840px) {
    padding: 36px 16px;
    flex-direction: column-reverse !important;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 50%;

  @media (max-width: 840px) {
    width: 100%;
  }
`;

const LeftSideTitle = styled(Text)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;

  @media (max-width: 840px) {
    margin-bottom: 4px;
  }
`;

const HomeLogo = styled(Home)`
  height: 32px;
  width: 32px;
  margin-right: 16px;
`;

const LocationLogo = styled(Location)`
  height: 32px;
  width: 32px;
  margin-right: 16px;
`;

const SmsLogo = styled(SMS)`
  height: 32px;
  width: 32px;
  margin-right: 16px;
`;

const ItemWrap = styled.div`
  display: flex;
`;

const TextWrap = styled.div``;

const Title = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 4px;
`;

const LeftSubTitle = styled(Text)`
  color: #555770 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
`;

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 840px) {
    width: 100%;
  }
`;

const RightSideTitle = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 32px;

  @media (max-width: 840px) {
    margin-bottom: 36px;
  }
`;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #e53535;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 12px;
  color: rgb(6, 194, 112) !important;
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  text-align: center;
  color: rgb(255, 59, 59) !important;
`;

const Note = styled.div`
  color: #555770 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0 32px 0;
  opacity: 0.8;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  background: ${({ disabled }) => (disabled ? '#EBEBF0' : '#1c1c28')};
  border: 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  min-width: 288px;
  padding: 12px 24px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  color: #fff;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 840px) {
    margin-bottom: 36px;
  }
`;

const StyledLabel = styled.label`
  color: #1c1c28;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  -webkit-appearance: none;

  &::placeholder {
    color: #555770 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }
`;

const StyledTextArea = styled.textarea`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  min-height: 144px;

  &::placeholder {
    color: #555770 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }
`;
